import React, { useState } from 'react';
import { Button, Provider, Alert } from '@actovos-consulting-group/ui-core';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Contact from './style';
import SectionTitle from './Components/SectionTitle';
import { Form, Field } from 'react-final-form';
import { Col, Container, Row } from 'react-bootstrap';
import { useAsyncFn } from 'react-use';

const required = value => (value ? undefined : 'Required');

export default function ContactOne() {
  const [captchaKey, setKey] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);

  const [{ loading }, makeReq] = useAsyncFn(async vals => {
    try {
      await axios.post('https://api.ittybam.com/contact', vals);
      setSubmitted(true);
    } catch (error) {}
  });

  const submit = vals => {
    return makeReq({
      ...vals,
      captchaKey,
      client: 'actovos',
    });
  };

  const verifyCallback = captchaKey => {
    setKey(captchaKey);
  };

  return (
    <Provider>
      <Contact backgroundColor="#f3f4f6">
        <Container>
          <Row>
            <Col className="col-xl-7 col-lg-7">
              <Contact.Box>
                <SectionTitle
                  subTitle="Contact Us"
                  title="Send A Message"
                  text="We are here to help answer your questions."
                  subTitleProps={{ mb: '10px' }}
                  titleProps={{ mb: '10px', as: 'h2' }}
                  mb="50px"
                />
              </Contact.Box>
              <Contact.From>
                {isSubmitted ? (
                  <Alert variant="success">
                    Thank you. We will be in touch.
                  </Alert>
                ) : (
                  <Form
                    onSubmit={submit}
                    render={({ handleSubmit, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Col xs="12" className="col-xs-12 mb-4">
                            <Field name="name" validate={required}>
                              {({ input: inp }) => (
                                <div className="form-floating">
                                  <input
                                    {...inp}
                                    className="form-control"
                                    placeholder="Leave a comment here"
                                    id="floatinginput"
                                  />
                                  <label htmlFor="floatinginput">
                                    Your Name
                                  </label>
                                </div>
                              )}
                            </Field>
                          </Col>
                          <Col xs="12" className="col-lg-6 mb-4">
                            <Field name="email" validate={required}>
                              {({ input: inp }) => (
                                <div className="form-floating">
                                  <input
                                    {...inp}
                                    className="form-control"
                                    placeholder="Leave a comment here"
                                    id="floatinginput"
                                  />
                                  <label htmlFor="floatinginput">
                                    Your Email
                                  </label>
                                </div>
                              )}
                            </Field>
                          </Col>
                          <Col xs="12" className="col-lg-6 mb-4">
                            <Field name="phone">
                              {({ input: inp }) => (
                                <div className="form-floating">
                                  <input
                                    {...inp}
                                    className="form-control"
                                    placeholder="Leave a comment here"
                                    id="floatinginput2"
                                  />
                                  <label htmlFor="floatinginput2">
                                    Phone number
                                  </label>
                                </div>
                              )}
                            </Field>
                          </Col>
                          <Col xs="12" className="col-lg-12">
                            <Field name="desc" validate={required}>
                              {({ input: inp }) => (
                                <div className="form-floating">
                                  <textarea
                                    {...inp}
                                    className="form-control"
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea3"
                                  />
                                  <label htmlFor="floatingTextarea3">
                                    Your Message Here{' '}
                                  </label>
                                </div>
                              )}
                            </Field>
                          </Col>
                          <Col xs="12" className="col-lg-12 my-5">
                            <ReCAPTCHA
                              sitekey="6LfdD8kUAAAAAKxqi8rDU_37huiZ43ZKgoLyhWoA"
                              theme="light"
                              onChange={verifyCallback}
                            />
                            <Button
                              className="mt-5"
                              size="xl"
                              type="submit"
                              disabled={!valid || !captchaKey}
                              loading={loading}
                            >
                              Send Message
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    )}
                  />
                )}
              </Contact.From>
            </Col>
            <Col xs="12" className="col-xl-5 col-lg-5">
              <Contact.WidgetsBox className="contact-widget-box">
                <Contact.WidgetsBoxTitle as="h2">
                  Get In Touch
                </Contact.WidgetsBoxTitle>
                <Row className="mt-5">
                  <Col xs="12" className="col-lg-12 col-sm-6">
                    <Contact.Widgets>
                      <Contact.WidgetsIcon>
                        <i className="fas fa-envelope" />
                      </Contact.WidgetsIcon>
                      <Contact.WidgetsBoxBody>
                        <Contact.WidgetsTitle as="h3">
                          visit us :
                        </Contact.WidgetsTitle>
                        <Contact.WidgetsText as="p">
                          1141 W Sheridan, Oklahoma City,{' '}
                          <br className="d-block" /> OK 73106, USA
                        </Contact.WidgetsText>
                      </Contact.WidgetsBoxBody>
                    </Contact.Widgets>
                  </Col>
                  <Col xs="12" className="col-lg-12 col-sm-6 active">
                    <Contact.Widgets>
                      <Contact.WidgetsIcon className="active">
                        <i className="fas fa-phone-alt" />
                      </Contact.WidgetsIcon>
                      <Contact.WidgetsBoxBody>
                        <Contact.WidgetsTitle as="h3">
                          mail us :
                        </Contact.WidgetsTitle>
                        <Contact.WidgetsText as="p">
                          help@actovos.com
                        </Contact.WidgetsText>
                      </Contact.WidgetsBoxBody>
                    </Contact.Widgets>
                  </Col>
                </Row>
              </Contact.WidgetsBox>
            </Col>
          </Row>
        </Container>
      </Contact>
    </Provider>
  );
}
